import request from '@http';

// 合同列表
export function getContracts(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/contract',
        params
    });
}

//2022/04/22 王江毅 获取退房信息
export function getContractsCheckoutData(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/ca/checkout`,
        params
    });
}

//2022/06/30 王江毅 退房查询信息
export function getCheckoutInfo(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/contract/checkout/info`,
        params
    });
}

//2022/06/30 王江毅 退房提交接口
export function contractCheckoutExit(data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/contract/checkout/exit`,
        data
    });
}

//2022/04/05 王江毅 合同导出
export function contractExport(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/contract/export`,
        responseType: 'blob',
        params
    });
}

//2022/04/05 王江毅 通过房源uuid和租户uuid获取合同?uuid=房源UUID&leasorUuid=人员UUID
export function getContractByUuidOrLeasorUuid(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/ca/current',
        params
    });
}

//2022/04/05 王江毅 续租 合同uuid和续租到什么时间?uuid=&dt=
export function renewal(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/ca/renewal`,
        params
    });
}

//2022/04/06 王江毅 作废合同 /api/gzf/ca/nullify?uuid=
export function nullify(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/ca/nullify`,
        params
    });
}

//2022/04/12 王江毅 作废合同 /api/gzf/ca/photos
export function photos(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/ca/photos`,
        params
    });
}

//2022/04/21 王江毅 退房
export function contractsCheckout(data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/ca/exit`,
        data
    });
}

//2022/04/25 王江毅 续租
export function renewalContract(data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/renewal/contract`,
        data
    });
}

//2022/05/09 王江毅 获取同住人
export function getFamilyList(params) {
    return request({
        method: 'get',
        url: `/api/gzf/leasor/family/getList`,
        params
    });
}

//2022/05/09 王江毅 添加同住人
export function saveFamily(uuid,data) {
    return request({
        method: 'put',
        url: `/api/gzf/leasor/family/${uuid}/save`,
        data
    });
}

//2022/05/09 王江毅 修改同住人
export function updateFamily(data) {
    return request({
        method: 'put',
        url: `/api/gzf/leasor/family/update`,
        data
    });
}

//2022/05/09 王江毅 删除同住人
export function delFamily(uuid) {
    return request({
        method: 'delete',
        url: `/api/gzf/leasor/family/${uuid}/del`
    });
}

//2022/05/25 王江毅 合同编辑备注
export function editComment(data) {
    return request({
        method: 'put',
        url: `/api/gzf/contract/${data.uuid}/comment`,
        data
    });
}

// 2022/10/19 王江毅 小程序续租合同审核列表
export function getContractAudit(params) {
    return request({
        method: 'get',
        url: `/api/gzf/contract/audit/page`,
        params
    });
}

// 2022/10/19 王江毅 续租审核通过
export function audit(uuid) {
    return request({
        method: 'put',
        url: `/api/gzf/contract/audit/audit/${uuid}`,
    });
}

// 2022/10/19 王江毅 小程序续租合同审核驳回
export function turn(uuid, turnCause) {
    return request({
        method: 'PUT',
        url: `/api/gzf/contract/audit/turn/${uuid}?turnCause=${turnCause}`,
    });
}

// 2022/10/19 王江毅 审核合同详情
export function info(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/contract/audit/info/${uuid}`
    });
}

// 2022/11/12 王江毅 获取合同详情
export function getContractData(data) {
    return request({
        method: 'post',
        url: `/api/nuonuo/contract/get/contract/data`,
        data,
    });
}

// 2022/11/12 王江毅 下载合同模板
export function expordPdf(uuid) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/expord/pdf/${uuid}`,
        responseType: 'blob',
    });
}

// 2022/11/12 王江毅 上传合同文件创建签署流程
export function uploadContract(data) {
    return request({
        method: 'post',
        url: `/api/nuonuo/contract/uploadContract`,
        data,
    });
}

// 2022/11/14 王江毅 创建个人模板印章
export function createPersonalCharter(data) {
    return request({
        method: 'post',
        url: `/api/nuonuo/contract/createPersonalCharter`,
        data,
    });
}

// 2022/11/14 王江毅 获取签署验证码
export function sendSmsCode(uuid) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/sendSmsCode/${uuid}`,
    });
}

// 2022/11/14 王江毅 合同签署（短信意愿认证）
export function signWithCaptcha(params) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/signWithCaptcha`,
        params,
    });
}

// 2022/11/14 王江毅 下载合同
export function downloadContract(uuid) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/downloadContract/${uuid}`,
    });
}

// 2022/11/16 王江毅 生成临时合同
export function create(data) {
    return request({
        method: 'post',
        url: `/api/new/contract/create`,
        data,
    });
}
// 2022/11/16 王江毅 合同完善
export function sign(params) {
    return request({
        method: 'get',
        url: `/api/new/contract/new/sign`,
        params
    });
}

// 2022/11/16 王江毅 添加nuonuo个人用户
export function personalRegister(params) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/personalRegister`,
        params
    });
}

// 2022/11/17 王江毅 添加nuonuo个人认证验证码
export function personalThreeElementsSms(params) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/personalThreeElementsSms`,
        params
    });
}

// 2022/11/17 王江毅 添加nuonuo个人认证认证
export function validateCertificateCode(params) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/validateCertificateCode`,
        params
    });
}

// 2022/11/17 王江毅 查看房间是否绑定房型
export function getApartmentData(uuid) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/get/apartment/data/${uuid}`,
    });
}
// 2022/11/21 王江毅 重新签约魔方电子合同
export function contractSign(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/ca/electronic/contract/sign/${uuid}`,
    });
}

// 2022/11/21 王江毅 新签合同判定
export function contractIsSign(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/apartment/contract/isSign/${uuid}`,
    });
}

// 2022/11/28 王江毅 获取个人人脸实名认证地址
export function getFaceAuthenticationUrl(params) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/getFaceAuthenticationUrl`,
        params,
    });
}

// 2022/12/02 王江毅 合同签约金额字段查询
export function getSignAmount(params) {
    return request({
        method: 'get',
        url: `/api/gzf/contract/sign/amount`,
        params,
    });
}

// 2022/12/26 王江毅 电子合同查询
export function getFlowUrl(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/contract/get/flow/url?uuid=${uuid}`,
    });
}
// 2022/01/05 王江毅 判断合同是否可续租
export function isRelet(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/apartment/contract/isRelet/${uuid}`,
    });
}

// 2022/01/12 王江毅 保租房新合同签约
export function bzfCreate(data) {
    return request({
        method: 'post',
        url: `/api/new/contract/bzf/create`,
        data,
    });
}

// 2022/01/12 王江毅 获取保租房临时合同信息
export function getBzfContractData(data) {
    return request({
        method: 'post',
        url: `/api/nuonuo/contract/get/bzf/contract/data`,
        data,
    });
}

// 2023/01/12 王江毅 下载保租房合同模板
export function expordBzfPdf(uuid) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/expord/bzf/pdf/${uuid}`,
        responseType: 'blob',
    });
}

// 2023/01/12 王江毅 上传合同文件创建保租房签署流程
export function uploadBzfContract(data) {
    return request({
        method: 'post',
        url: `/api/nuonuo/contract/bzf/uploadContract`,
        data,
    });
}

// 2023/01/12 王江毅 保租房合同签署（短信意愿认证）
export function bzfSignWithCaptcha(params) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/bzf/signWithCaptcha`,
        params,
    });
}

// 2022/01/12 王江毅 保租房合同完善
export function bzfSign(params) {
    return request({
        method: 'get',
        url: `/api/new/contract/bzf/new/sign`,
        params
    });
}

// 2022/01/13 王江毅 根据父房间uuid查询合同
export function getSonAllList(params) {
    return request({
        method: 'get',
        url: `/api/new/contract/son/all/list`,
        params
    });
}

// 2023/01/31 王江毅 重签电子合同
export function bzfInvalid(uuid) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/bzf/invalid/${uuid}`
    });
}

// 2023/01/31 王江毅 保租房电子合同作废
export function bzfContractInvalid(uuid) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/bzf/contract/invalid/${uuid}`
    });
}

// 2023/04/18 王江毅 查询合同结束时间是否在春节附近
export function getIsGregorin(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/date/isGregorin`,
        params
    });
}

// 2023/05/06 查询手机号是否是该身份证注册的
export function getPhoneCheck(params) {
    return request({
        method: 'get',
        url: `/api/gzf/rent/user/phone/check`,
        params
    });
}

// 2023/07/03 王江毅 查找个人租客
export function getNuonuoUser(params) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/getNuonuoUser`,
        params,
    });
}

// 2023/08/31 王江毅 诺诺获取人脸意愿认证地址
export function bzfWillingAddress(params) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/bzf/willing/address`,
        params,
    });
}

// 2023/09/05 王江毅 查询诺诺电子合同状态
export function getSigningStatus(params) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/signing/status`,
        params,
    });
}

// 2023/09/07 王江毅 查询合同备案信息（组装参数）
export function getContractRegisteredInfo(params) {
    return request({
        method: 'get',
        url: `/api/gzf/zjj/contract/get/ContractRegisteredInfo`,
        params,
    });
}

// 2023/09/07 王江毅 提交合同备案功能
export function uploadContractInfo(data) {
    return request({
        method: 'post',
        url: `/api/gzf/zjj/contract/get/uploadContractInfo`,
        data,
    });
}

// 2023/09/08 王江毅 合同备案查询合同是否备案
export function getContractIsRegistered(params) {
    return request({
        method: 'get',
        url: `/api/gzf/zjj/contract/get/ContractIsRegistered`,
        params,
    });
}

// 2023/09/08 王江毅 查询合同备案信息（组装参数）
export function getContractRegisteredInfoByLast(params) {
    return request({
        method: 'get',
        url: `/api/gzf/zjj/contract/get/ContractRegisteredInfoByLast`,
        params,
    });
}

// 2023/09/08 王江毅 查询审核状态
export function queryContractAuditStatus(params) {
    return request({
        method: 'get',
        url: `/api/gzf/zjj/contract/get/queryContractAuditStatus`,
        params,
    });
}

// 2023/09/18 张晓瑜 查询房间是否有未核验资产
export function getAssetsverifyApi(params) {
    return request({
        method: 'get',
        url: `/api/apartment/assets/is/verify`,
        params,
    });
}

// 2023-11-28-陈守亮-杂费删除接口
export function getdeleteclickApi(params) {
    return request({
        method: 'get',
        url: `/api/gzf/paybill/web/delete`,
        params,
    });
}

// 2023/11/01 王江毅 查询诺诺电子合同状态---陈守亮-同步
export function getNuoNuoContractStatusApi(params) {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/contract/status`,
        params,
    });
}
// 2024/04/19 张晓瑜 退房--处理记录
export function getDisposeLogList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/check/out/dispose/log/list',
        params
    })
}
// 2024/04/19 张晓瑜 退房--驳回
export function getreturnReview(data) {
    return request({
        method: 'post',
        url: `/api/gzf/rent/return/review`,
        data,
    });
}

// 2024/08/16 张晓瑜 是否
export function getIsRenovationApi(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/apartment/isRenovation/get/${uuid}`,
    });
}
// 2024/09/10 张晓瑜 获取电子合同类型
export function getContractElectType(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/get_contract_elect_type`,
        params,
    });
}